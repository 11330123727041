import SelectModeType from '../../components/SelectModeType/SelectModeType'
import { FormProvider, useForm } from 'react-hook-form'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { setModeType } from '../../modules/auth/features/auth.slice'
interface IAuctionMode {
    mode_type: 'participate' | 'conduct'
}
const AuctionMode = () => {
    const { $t } = useIntl()
    const dispatch = useDispatch()
    const methods = useForm<IAuctionMode>()
    const onSubmit = (data: IAuctionMode) => {
        dispatch(setModeType({ mode: data.mode_type }))
    }
    return (
        <div className='d-flex w-100 h-100 flex-center'>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="card">
                    <div className="card-header">
                        <h2 className="fw-bold d-flex align-items-center text-gray-900">
                            {$t({ id: 'AUCTION_MODE.PAGE.TITLE' })}
                            {/* <span className="ms-1" data-bs-toggle="tooltip" aria-label="Please Select mode in which you operate">
                                <i className="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                            </span> */}
                        </h2>
                    </div>
                    <div className="card-body">
                        <FormProvider {...methods}>
                            <SelectModeType />
                        </FormProvider>
                    </div>

                    <div className="card-footer d-flex justify-content-end">
                        <Button variant='primary' type='submit'>{$t({ id: 'AUTH.LOGIN.CONTINUE_BUTTON' })}</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AuctionMode