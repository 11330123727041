import { useFormContext } from 'react-hook-form';
import FieldErrorText from '../FormBuilder/FieldErrorText';
import { useIntl } from 'react-intl';
import SelectType, { ISelectTypeItem } from '../SelectType/SelectType';

const SelectModeType = () => {
    const { register, formState: { errors } } = useFormContext();
    const { $t } = useIntl()
    const modeTypes: ISelectTypeItem[] = [
        {
            id: 'conduct_mode',
            title: $t({ id: 'AUCTION_MODE.CONDUCT.TITLE' }),
            description: $t({ id: 'AUCTION_MODE.CONDUCT.DESCRIPTION' }),
            value: 'conduct'
        },
        {
            id: 'participate',
            title: $t({ id: 'AUCTION_MODE.PARTICIPATE.TITLE' }),
            description: $t({ id: 'AUCTION_MODE.PARTICIPATE.DESCRIPTION' }),
            value: 'participate'
        }
    ]

    return <SelectType items={modeTypes} error={errors?.mode_type?.message as string || ''} {...register('mode_type', { value: 'conduct', required: { value: true, message: 'Mode type is required' } })} />
}

export default SelectModeType