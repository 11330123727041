export default {
    "SITE.GENERAL.APP_NAME": "ApnaJugaad.co.in",
    "SITE.GENERAL.DESCRIPTION": "apnajugaad.co.in में आपका स्वागत है",
    "COMMON.TEXT.PARITICIPANT": "पार्टिसिपेंट",
    "COMMON.TEXT.UNIT": "यूनिट",
    "COMMON.TEXT.EMAIL": "ईमेल",
    "COMMON.TEXT.PHONE_NUMBER": "फ़ोन नंबर",
    "COMMON.TEXT.IMPORT": "आयात",
    "COMMON.TEXT.UPLOAD": "अपलोड",
    "COMMON.TEXT.NEXT": "अगला चरण",
    "COMMON.TEXT.BACK": "पिछला",
    "COMMON.TEXT.FINISH": "समाप्त",
    "COMMON.TEXT.PUBLISH": "प्रकाशित",
    "MENU.HEADER.CONDUCT_MODE": "कंडक्ट",
    "MENU.HEADER.PARTICIPATE_MODE": "पार्टिसिपेंट",
    "MENU.HEADER.MY_PROFILE": "मेरी प्रोफाइल",
    "MENU.HEADER.SETTINGS": "सेटिंग्स",
    "MENU.HEADER.MANAGE_USER": "उपयोगकर्ता और भूमिकाएँ प्रबंधित करें",
    "MENU.HEADER.HELP": "सहायता",
    "MENU.HEADER.SIGN_OUT": "साइन आउट करें",
    "TRANSLATOR.SELECT": "अपनी भाषा चुनें",
    "MENU.HEADER.ASIDE_PROFILE_COMPLETION": "प्रोफ़ाइल समापन",
    "MENU.NEW": "नया",
    "MENU.ACTIONS": "क्रियाएँ",
    "MENU.CREATE_POST": "नया पोस्ट बनाएं",
    "MENU.PAGES": "पृष्ठ",
    "MENU.FEATURES": "विशेषताएँ",
    "MENU.APPS": "ऐप्स",
    "MENU.DASHBOARD": "डैशबोर्ड",
    "AUTH.GENERAL.OR": "या",
    "AUTH.GENERAL.SUBMIT_BUTTON": "सबमिट करें",
    "AUTH.GENERAL.NO_ACCOUNT": "खाता नहीं है?",
    "AUTH.GENERAL.SIGNUP_BUTTON": "साइन अप करें",
    "AUTH.GENERAL.FORGOT_BUTTON": "पासवर्ड भूल गए",
    "AUTH.GENERAL.BACK_BUTTON": "वापस",
    "AUTH.GENERAL.PRIVACY": "गोपनीयता",
    "AUTH.GENERAL.LEGAL": "कानूनी",
    "AUTH.GENERAL.CONTACT": "संपर्क करें",
    "AUTH.LOGIN.LOGIN_TYPE": "साइन इन का विकल्प चुनें",
    "AUTH.LOGIN.LOGIN_TYPE_USER_ID": "आपका मौजूदा पासवर्ड",
    "AUTH.LOGIN.LOGIN_TYPE_OTP": "वन टाइम पासवर्ड (OTP)",
    "AUTH.LOGIN.TITLE": "साइन इन करें",
    "AUTH.LOGIN.OR_WITH_EMAIL": "या ईमेल से",
    "AUTH.LOGIN.ERROR_MESSAGE": "एक त्रुटि हुई: {error}",
    "AUTH.LOGIN.USER_ID.LABEL": "यूजर आईडी",
    "AUTH.LOGIN.USER_ID.PLACEHOLDER": "यूजर आईडी",
    "AUTH.LOGIN.USER_ID.ERROR": "{error}",
    "AUTH.LOGIN.PASSWORD.LABEL": "पासवर्ड",
    "AUTH.LOGIN.PASSWORD.PLACEHOLDER": "पासवर्ड",
    "AUTH.LOGIN.PASSWORD.ERROR": "{error}",
    "AUTH.LOGIN.FORGOT_PASSWORD": "पासवर्ड भूल गए?",
    "AUTH.LOGIN.CONTINUE_BUTTON": "जारी रखें",
    "AUTH.LOGIN.LOADING_MESSAGE": "कृपया प्रतीक्षा करें...",
    "AUTH.LOGIN.CREATE_ACCOUNT_MESSAGE": "खाता बनाएं?",
    "AUTH.LOGIN.SIGNUP_LINK": "साइन अप करें",
    "AUTH.LOGIN_WITH_PHONE.TITLE": "साइन इन करें",
    "AUTH.LOGIN_WITH_PHONE.SOCIAL_BUTTON_ENABLED": "या फोन नंबर के साथ",
    "AUTH.LOGIN_WITH_PHONE.ERROR_MESSAGE": "उफ़ कुछ गलत हो गया। कृपया बाद में पुनः प्रयास करें!",
    "AUTH.LOGIN_WITH_PHONE.PHONE_NUMBER.LABEL": "फोन नंबर",
    "AUTH.LOGIN_WITH_PHONE.PHONE_NUMBER.PLACEHOLDER": "फोन नंबर",
    "AUTH.LOGIN_WITH_PHONE.GET_OTP_BUTTON": "ओटीपी प्राप्त करें",
    "AUTH.LOGIN_WITH_PHONE.LOADING_MESSAGE": "कृपया प्रतीक्षा करें...",
    "AUTH.LOGIN_WITH_PHONE.CREATE_ACCOUNT": "खाता बनाएं?",
    "AUTH.LOGIN_WITH_PHONE.SIGNUP_LINK": "साइन अप करें",
    "AUTH.LOGIN_WITH_PHONE.OTP_VERIFY_BUTTON": "ओटीपी सत्यापित करें",
    "AUTH.LOGIN_WITH_PHONE.VERIFYING_OTP": "सत्यापन हो रहा है...",
    "AUTH.LOGIN_WITH_PHONE.CANCEL_BUTTON": "रद्द करें",
    "AUTH.REGISTRATION.BACK_TO_LOGIN": "लॉगिन पर वापस जाएं",
    "AUTH.REGISTRATION.STEP_1.HEADING": "सामान्य विवरण",
    "AUTH.REGISTRATION.STEP_2.HEADING": "व्यवसाय विवरण",
    "AUTH.REGISTRATION.STEP_3.HEADING": "कर विवरण",
    "AUTH.REGISTRATION.STEP_4.HEADING": "पुष्टि करें और जमा करें",
    "AUTH.REGISTRATION.STEP_1.DESCRIPTION": "अपनी बुनियादी जानकारी सेटअप करें",
    "AUTH.REGISTRATION.STEP_2.DESCRIPTION": "अपने व्यवसाय की जानकारी सेटअप करें",
    "AUTH.REGISTRATION.STEP_3.DESCRIPTION": "अपने कर संबंधी जानकारी प्रदान करें",
    "AUTH.REGISTRATION.STEP_4.DESCRIPTION": "कृपया पुष्टि करें और जमा करें",
    "AUTH.REGISTRATION.PREVIOUS": "पिछला",
    "AUTH.REGISTRATION.NEXT_STEP": "अगला चरण",
    "AUTH.REGISTRATION.SUBMIT": "जमा करें",
    "AUTH.REGISTRATION.COMPANY_NAME.LABEL": "कंपनी का नाम*",
    "AUTH.REGISTRATION.COMPANY_NAME.PLACEHOLDER": "कंपनी का नाम",
    "AUTH.REGISTRATION.COMPANY_COUNTRY.LABEL": "देश*",
    "AUTH.REGISTRATION.COMPANY_COUNTRY.PLACEHOLDER": "देश",
    "AUTH.REGISTRATION.COMPANY_STATE.LABEL": "राज्य*",
    "AUTH.REGISTRATION.COMPANY_STATE.PLACEHOLDER": "राज्य",
    "AUTH.REGISTRATION.COMPANY_CITY.LABEL": "शहर*",
    "AUTH.REGISTRATION.COMPANY_CITY.PLACEHOLDER": "शहर",
    "AUTH.REGISTRATION.COMPANY_EMAIL_1.LABEL": "प्राथमिक ईमेल*",
    "AUTH.REGISTRATION.COMPANY_EMAIL_1.PLACEHOLDER": "प्राथमिक ईमेल",
    "AUTH.REGISTRATION.COMPANY_EMAIL_2.LABEL": "द्वितीयक ईमेल",
    "AUTH.REGISTRATION.COMPANY_EMAIL_2.PLACEHOLDER": "द्वितीयक ईमेल",
    "AUTH.REGISTRATION.COMPANY_MOBILE_1.LABEL": "प्राथमिक फोन*",
    "AUTH.REGISTRATION.COMPANY_MOBILE_1.PLACEHOLDER": "प्राथमिक फोन",
    "AUTH.REGISTRATION.COMPANY_MOBILE_2.LABEL": "द्वितीयक फोन",
    "AUTH.REGISTRATION.COMPANY_MOBILE_2.PLACEHOLDER": "द्वितीयक फोन",
    "AUTH.REGISTRATION.COMPANY_TYPE_OF_BUSINESS.LABEL": "व्यवसाय का प्रकार",
    "AUTH.REGISTRATION.COMPANY_TYPE_OF_BUSINESS.PLACEHOLDER": "व्यवसाय का प्रकार",
    "AUTH.REGISTRATION.PRODUCT_GROUP_IDS.LABEL": "उत्पाद समूह",
    "AUTH.REGISTRATION.PRODUCT_GROUP_IDS.FILTER_PLACEHOLDER": "उत्पाद समूह खोजें",
    "AUTH.REGISTRATION.OTHER_PRODUCT_GROUP.PLACEHOLDER": "अन्य उत्पाद समूह दर्ज करें",
    "AUTH.REGISTRATION.VENDOR_TYPE.LABEL": "विक्रेता का प्रकार*",
    "AUTH.REGISTRATION.VENDOR_TYPE.PLACEHOLDER": "विक्रेता का प्रकार",
    "AUTH.REGISTRATION.CURRENCY_CODE.LABEL": "मुद्रा*",
    "AUTH.REGISTRATION.CURRENCY_CODE.PLACEHOLDER": "मुद्रा",
    "AUTH.REGISTRATION.COMPANY_PAN_NUMBER.LABEL": "पैन",
    "AUTH.REGISTRATION.COMPANY_PAN_NUMBER.PLACEHOLDER": "पैन",
    "AUTH.REGISTRATION.COMPANY_TAX_NUMBER.LABEL": "जी.ऐस.टी",
    "AUTH.REGISTRATION.COMPANY_TAX_NUMBER.PLACEHOLDER": "जी.ऐस.टी",
    "AUTH.REGISTRATION.UPLOAD_COMPANY_PAN_NUMBER.LABEL": "अपलोड पैन",
    "AUTH.REGISTRATION.UPLOAD_COMPANY_PAN_NUMBER.PLACEHOLDER": "अपलोड पैन",
    "AUTH.REGISTRATION.UPLOAD_COMPANY_TAX_NUMBER.LABEL": "अपलोड जी.ऐस.टी",
    "AUTH.REGISTRATION.UPLOAD_COMPANY_TAX_NUMBER.PLACEHOLDER": "अपलोड जी.ऐस.टी",
    "AUTH.REGISTRATION.COMPANY_REGISTRATION_NUMBER.LABEL": "पंजीकरण संख्या",
    "AUTH.REGISTRATION.POSTAL_ADDRESS.LABEL": "पता",
    "AUTH.REGISTRATION.POSTAL_ADDRESS.PLACEHOLDER": "पता",
    "AUTH.REGISTRATION.PASSWORD.LABEL": "पासवर्ड",
    "AUTH.REGISTRATION.PASSWORD.PLACEHOLDER": "पासवर्ड",
    "AUTH.REGISTRATION.CONFIRM_PASSWORD.LABEL": "पासवर्ड की पुष्टि करें",
    "AUTH.REGISTRATION.CONFIRM_PASSWORD.PLACEHOLDER": "पासवर्ड की पुष्टि करें",
    "AUTH.REGISTRATION.TERMS_AND_CONDITIONS.LABEL": "नियम और शर्तें",
    "PASSWORDINPUT.OTP_MODAL.TITLE": "सत्यापित करने के लिए ओटीपी दर्ज करें",
    "PASSWORDINPUT.VERIFY.BUTTON": "सत्यापित करें",
    "PASSWORDINPUT.VERIFY.BUTTON.LOADING": "सत्यापन",
    "BUTTON.CANCEL": "रद्द करें",
    "BUTTON.SUBMIT": "सबमिट करें",
    "OTPINPUT.LABEL": "ओटीपी दर्ज करें",
    "OTPINPUT.RESEND": "पुन: भेजें",
    "OTPINPUT.RESEND_LOADING": "ओटीपी दोबारा भेजें {time}sec...",
    "PENDING_APPROVAL.HEADING": "लंबित अनुमोदन",
    "PENDING_APPROVAL.DESCRIPTION": "आपकी प्रोफ़ाइल अनुमोदन हेतु लंबित है|",
    "AUCTION_MODE.PAGE.TITLE": "मोड चुनें",
    "AUCTION_MODE.CONDUCT.TITLE": "कंडक्ट मोड",
    "AUCTION_MODE.CONDUCT.DESCRIPTION": "इवेंट बनाएं, नीलामी आयोजित करें और बोली प्रबंधित करें।",
    "AUCTION_MODE.PARTICIPATE.TITLE": "पार्टिसिपेंट मोड",
    "AUCTION_MODE.PARTICIPATE.DESCRIPTION": "आयोजनों और बोली में भाग लें",
    // Tender new
    "TENDER_NEW_STEP.1.TITLE": "सामान्य विवरण",
    "TENDER_NEW_STEP.2.TITLE": "दस्तावेज़ संलग्न करें",
    "TENDER_NEW_STEP.3.TITLE": "नियम और शर्तें संलग्न करें",
    "TENDER_NEW_STEP.4.TITLE": "आपूर्तिकर्ता चयन",
    "TENDER_NEW_STEP.5.TITLE2": "लाइन आइटम",
    "TENDER_NEW_STEP.5.TITLE": "लाइन आइटम जोड़ें",
    "TENDER_NEW_STEP.6.TITLE": "सारांश और प्रकाशित करें",
    "TENDER_NEW.FORM.TITLE.LABEL": "शीर्षक",
    "TENDER_NEW.FORM.START_DATE.LABEL": "प्रारंभ तिथि",
    "TENDER_NEW.FORM.END_DATE.LABEL": "अंतिम तिथि",
    "TENDER_NEW.FORM.COMPANY_NAME.LABEL": "कंपनी का नाम",
    "TENDER_NEW.FORM.CURRENCY_CODE.LABEL": "मुद्रा कोड",
    "TENDER_NEW.FORM.HISTORIC_PRICE.LABEL": "ऐतिहासिक मूल्य",
    "TENDER_NEW.FORM.VENDOR_TYPE.LABEL": "विक्रेता का प्रकार",
    "TENDER_NEW.FORM.TENDER_RANGE.LABEL": "टेंडर रेंज",
    "TENDER_NEW.FORM.PRODUCT_GROUP.LABEL": "उत्पाद समूह",
    "TENDER_NEW.FORM.DESCRIPTION.LABEL": "विवरण",
    "TENDER_NEW.FORM.SHOW_RANKING.LABEL": "श्रेणी दिखाएं",
    "TENDER_NEW.FORM.AUTO_EXTEND.LABEL": "स्वतः विस्तार",
    "TENDER_NEW.FORM.SEALED_BID.LABEL": "सील बोली",
    "TENDER_NEW.FORM.RFQ_FOR.LABEL": "आरएफक्यू के लिए",
    "TENDER_NEW.FORM.RFQ_FOR_BUY.LABEL": "खरीदें",
    "TENDER_NEW.FORM.RFQ_FOR_SELL.LABEL": "बेचें",
    "TENDER_NEW.FORM.SELECT_COMPANY.PLACEHOLDER": "कंपनी का चयन करें",
    "TENDER_NEW.FORM.CURRENCY_CODE.PLACEHOLDER": "मुद्रा कोड",
    "TENDER_NEW.FORM.PRODUCT_GROUP.PLACEHOLDER": "उत्पाद समूह",
    "TENDER_NEW.FORM.TITLE.PLACEHOLDER": "शीर्षक",
    "TENDER_NEW.FORM.START_DATE.PLACEHOLDER": "प्रारंभ तिथि",
    "TENDER_NEW.FORM.END_DATE.PLACEHOLDER": "अंतिम तिथि",
    "TENDER_NEW.FORM.DESCRIPTION.PLACEHOLDER": "विवरण",
    "TENDER_NEW.FORM.HISTORIC_PRICE.PLACEHOLDER": "ऐतिहासिक मूल्य",
    "TENDER_NEW.FORM.VENDOR_TYPE.PLACEHOLDER": "विक्रेता का प्रकार",
    "TENDER_NEW.FORM.TENDER_RANGE.PLACEHOLDER": "टेंडर रेंज",
    "TENDER_NEW.FORM.FILES.LABEL": "फाइलें अपलोड करें",
    "TENDER_NEW.FORM.TERMS_AND_CONDITION.LABEL": "नियम और शर्तें",
    "TENDER_NEW.FORM.TERMS_AND_CONDITION.PLACEHOLDER": "नियम और शर्तें",
    "TENDER_NEW.FORM.PR_ID.LABEL": "पी.आर आई.दी ",
    "TENDER_NEW.FORM.PR_ID.PLACEHOLDER": "पी.आर आई.दी ",
    "TENDER_NEW.FORM.APPROVAL_AUTHORITY.LABEL": "मंजूरी देने वाला अधिकारी",
    "TENDER_NEW.FORM.APPROVAL_AUTHORITY.PLACEHOLDER": "मंजूरी देने वाला अधिकारी",
    "TENDER_NEW.FORM.PARITCIPANT.SELECT": "पार्टिसिपेंट चुनें",
    "TENDER_NEW.FORM.UNIT.SELECT": "यूनिट चुनें",
    "TENDER_NEW.FORM.PRODUCT_GROUP.SELECT": "उत्पाद समूह चुनें",
    "TENDER_NEW.FORM.INVITE_PARTICIPANT": "पार्टिसिपेंट को आमंत्रित करें",
    "TENDER_NEW.FORM.ADD_PARTICIPANT": "पार्टिसिपेंट को जोड़ें",
    "TENDER_NEW.TITLE": "नया RFQ",
    "TENDER_DRAFT.TITLE": "ड्राफ्ट RFQ",
    "TENDER_APPROVE.TITLE": "स्वीकृत RFQ",

    "REVERSE_AUCTION_NEW.TITLE": "नया रिवर्स नीलामी ",
    "REVERSE_AUCTION_DRAFT.TITLE": "ड्राफ्ट रिवर्स नीलामी ",
    "REVERSE_AUCTION_APPROVE.TITLE": "स्वीकृत रिवर्स नीलामी ",

    "FORWARD_AUCTION_NEW.TITLE": "नया अग्रेषित नीलामी",
    "FORWARD_AUCTION_NEW_STEP.1.TITLE": "सामान्य विवरण",
    "FORWARD_AUCTION_NEW_STEP.2.TITLE": "लाइन आइटम्स",
    "FORWARD_AUCTION_NEW_STEP.2.TITLE2": "लाइन आइटम्स जोड़ें",
    "FORWARD_AUCTION_NEW_STEP.3.TITLE": "आपूर्तिकर्ता चयन",
    "FORWARD_AUCTION_NEW_STEP.4.TITLE": "दस्तावेज़ अपलोड",
    "FORWARD_AUCTION_NEW_STEP.5.TITLE": "नियम और शर्तें संलग्न करें",
    "FORWARD_AUCTION_NEW_STEP.6.TITLE": "सारांश और प्रकाशित करें",

    "FORWARD_AUCTION_DRAFT.TITLE": "ड्राफ्ट फॉरवर्ड नीलामी ",
    "FORWARD_AUCTION_APPROVE.TITLE": "स्वीकृत फॉरवर्ड नीलामी ",

    "EQUIPMENT_LISTING_NEW.TITLE": "नया उपकरण सूची",
    "EQUIPMENT_LISTING_NEW_STEP.1.TITLE": "सामान्य विवरण",
    "EQUIPMENT_LISTING_NEW_STEP.2.TITLE": "दस्तावेज़ अपलोड",
    "EQUIPMENT_LISTING_NEW_STEP.3.TITLE": "नियम और शर्तें संलग्न करें",
    "EQUIPMENT_LISTING_NEW_STEP.4.TITLE": "संपर्क जानकारी",
    "EQUIPMENT_LISTING_NEW_STEP.5.TITLE": "सारांश और प्रकाशित करें",

    "EQUIPMENT_LISTING_DRAFT.TITLE": "प्रारूप उपकरण सूची",
    "EQUIPMENT_LISTING_APPROVE.TITLE": "स्वीकृत उपकरण सूची",
    
    // navigation 
    "NAVIGATION.CONDUCT.MAIN": "मुख्य",
    "NAVIGATION.CONDUCT.HOME": "मुख्य पृष्ठ",
    "NAVIGATION.CONDUCT.MASTERS": "मास्टर्स",
    "NAVIGATION.CONDUCT.USERS": "उपयोगकर्ता",
    "NAVIGATION.CONDUCT.DEPARTMENT": "विभाग",
    "NAVIGATION.CONDUCT.PRODUCT": "उत्पाद",
    "NAVIGATION.CONDUCT.UNIT": "इकाई",
    "NAVIGATION.CONDUCT.TERMS_AND_CONDITION": "नियम और शर्तें",
    "NAVIGATION.CONDUCT.COMPANY": "कंपनी",
    "NAVIGATION.CONDUCT.SUPPLIERS": "आपूर्तिकर्ता",
    "NAVIGATION.CONDUCT.EVENTS": "ईवेंट्स",
    "NAVIGATION.CONDUCT.TENDER_RFQ": "निविदा (RFQ)",
    "NAVIGATION.CONDUCT.NEW": "नया",
    "NAVIGATION.CONDUCT.DRAFT": "ड्राफ्ट",
    "NAVIGATION.CONDUCT.APPROVE": "स्वीकृत करें",
    "NAVIGATION.CONDUCT.REVERSE_AUCTION": "रिवर्स नीलामी",
    "NAVIGATION.CONDUCT.FORWARD_AUCTION": "फॉरवर्ड नीलामी",
    "NAVIGATION.CONDUCT.EQUIPMENT_LISTING": "उपकरण सूची",
    "NAVIGATION.CONDUCT.PUBLISH": "प्रकाशित करें",
    "NAVIGATION.CONDUCT.REPORTS": "रिपोर्ट्स",
    "NAVIGATION.CONDUCT.TENDER_REPORTS": "निविदा",
    "NAVIGATION.CONDUCT.REVERSE_AUCTION_REPORTS": "रिवर्स नीलामी",
    "NAVIGATION.CONDUCT.FORWARD_AUCTION_REPORTS": "फॉरवर्ड नीलामी",
    "NAVIGATION.CONDUCT.EQUIPMENT_LISTING_REPORTS": "उपकरण सूची",
    "NAVIGATION.CONDUCT.CONFIGURATION": "कॉन्फ़िगरेशन",
    "NAVIGATION.CONDUCT.EVENT_NAMING_CONVENTION": "ईवेंट नामकरण परंपरा",
    "NAVIGATION.CONDUCT.PROFILE": "प्रोफ़ाइल",
    "NAVIGATION.PARTICIPATE.MAIN": "मुख्य",
    "NAVIGATION.PARTICIPATE.HOME": "मुख्य पृष्ठ",
    "NAVIGATION.PARTICIPATE.PARTICIPATE": "भाग लें",
    "NAVIGATION.PARTICIPATE.LIVE_TENDER_RFQ": "सजीव निविदा (आरएफक्यू)",
    "NAVIGATION.PARTICIPATE.LIVE_REVERSE_AUCTION": "सजीव रिवर्स नीलामी",
    "NAVIGATION.PARTICIPATE.LIVE_FORWARD_AUCTION": "सजीव फॉरवर्ड नीलामी",
    "NAVIGATION.PARTICIPATE.REPORTS": "रिपोर्ट्स",
    "NAVIGATION.PARTICIPATE.TENDER_REPORTS": "निविदा रिपोर्ट्स",
    "NAVIGATION.PARTICIPATE.REVERSE_AUCTION_REPORTS": "रिवर्स नीलामी रिपोर्ट्स",
    "NAVIGATION.PARTICIPATE.FORWARD_AUCTION_REPORTS": "फॉरवर्ड नीलामी रिपोर्ट्स",
    "NAVIGATION.PARTICIPATE.PROFILE": "प्रोफ़ाइल"
}