import { IModules } from "../pages/moduleCreator/models/Modules.interfaces";

export const mockParticipateModeRoutes: {
    status: boolean;
    message?: string;
    data: {
        id: number;
        user_id: string;
        role: string;
        access: string;
        permissions: IModules[];
        home: string
    };
} = {
    data: {
        id: 30001,
        user_id: "142323",
        role: "Vendor",
        access: "v",
        home: "/dashboard",
        permissions: [
            {
                id: 1,
                type: "header",
                path: undefined,
                title: "NAVIGATION.PARTICIPATE.MAIN",
                folder: undefined,
                element: undefined,
                parentId: undefined,
                generateCRUD: true,
                level: 1,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 1,
                    hidden: true,
                    icon: "document",
                    iconType: "duotone"
                }
            },
            {
                id: 40,
                type: "item",
                path: "dashboard",
                title: "NAVIGATION.PARTICIPATE.HOME",
                folder: "participateDashboard",
                element: "ParticipateDashboard",
                parentId: 1,
                generateCRUD: false,
                level: 2,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 2,
                    hidden: false,
                    icon: "home",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Dashboard",
                        key: "participate-dashboard",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },


            // Participate
            {
                id: 10,
                type: "list",
                path: "participate",
                title: "NAVIGATION.PARTICIPATE.PARTICIPATE",
                folder: undefined,
                element: undefined,
                parentId: 1,
                generateCRUD: true,
                level: 2,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 10,
                    hidden: false,
                    icon: "people",
                    iconType: "outline"
                }
            },
            // Event tender

            {
                id: 35,
                type: "item",
                path: "participate/live-tender",
                title: "NAVIGATION.PARTICIPATE.LIVE_TENDER_RFQ",
                folder: "participate",
                element: "LiveTender",
                parentId: 10,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 35,
                    hidden: false,
                    icon: "abstract-22",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Add Live Tender",
                        key: "create",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            {
                id: 13,
                type: "item",
                path: "participate/live-reverse-auction",
                title: "NAVIGATION.PARTICIPATE.LIVE_REVERSE_AUCTION",
                folder: "participate",
                element: "LiveReverseAuction",
                parentId: 10,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 13,
                    hidden: false,
                    icon: "abstract",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Live Reverse Auction ",
                        key: "live-reverse-auction",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }]
            },
            {
                id: 14,
                type: "item",
                path: "participate/live-forward-auction",
                title: "NAVIGATION.PARTICIPATE.LIVE_FORWARD_AUCTION",
                folder: "participate",
                element: "LiveForwardAuction",
                parentId: 10,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 14,
                    hidden: false,
                    icon: "abstract-44",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Live Forward Auction",
                        key: "live-forward-auction",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },

            // Reports
            {
                id: 27,
                type: "list",
                path: "reports",
                title: "NAVIGATION.PARTICIPATE.REPORTS",
                folder: undefined,
                element: undefined,
                parentId: 1,
                generateCRUD: true,
                level: 2,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 27,
                    hidden: false,
                    icon: "tablet-book",
                    iconType: "outline"
                }
            },
            {
                id: 28,
                type: "item",
                path: "reports/tender",
                title: "NAVIGATION.PARTICIPATE.TENDER_REPORTS",
                folder: "reports",
                element: "TenderReports",
                parentId: 27,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 28,
                    hidden: false,
                    icon: "document",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Tender Reports",
                        key: "tender-reports",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                    }
                ]
            },
            {
                id: 29,
                type: "item",
                path: "reports/reverse-auction",
                title: "NAVIGATION.PARTICIPATE.REVERSE_AUCTION_REPORTS",
                folder: "reports",
                element: "ReverseAuctionReports",
                parentId: 27,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 29,
                    hidden: false,
                    icon: "update-file",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Reverse Auction Reports",
                        key: "reverse-auction-reports",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },
            {
                id: 30,
                type: "item",
                path: "reports/forward-auction",
                title: "NAVIGATION.PARTICIPATE.FORWARD_AUCTION_REPORTS",
                folder: "reports",
                element: "ForwardAuctionReports",
                parentId: 27,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 30,
                    hidden: false,
                    icon: "file-right",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Forward Auction Reports",
                        key: "forward-auction-reports",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },



            {
                id: -8,
                type: 'item',
                path: 'profile',
                element: "MyProfile",
                title: "NAVIGATION.PARTICIPATE.PROFILE",
                parentId: -1,
                sidebarOptions: {
                    icon: "switch",
                    hidden: true
                },
            },

        ]
    },
    message: "",
    status: true
}