import passwordComplexity from "joi-password-complexity";

import Joi from "./joi";

class FormValidationRules {
    get username() {
        return Joi.string()
            .regex(
                new RegExp(
                    /^[A-Za-z](?=[A-Za-z0-9_.]{3,31}$)[a-zA-Z0-9_]*\.?[a-zA-Z0-9_]*$/
                )
            )
            .required()
            .messages({
                "string.pattern.base":
                    "Use 4 to 32 ch aracters and start with a letter. You may use letters, numbers, underscores, and one dot (.)",
            })
            .label("Username");
    }
    set username(value) {
        this.throwSetError();
    }

    get email() {
        return Joi.string()
            .email({ tlds: { allow: false } })

            .label("Email");
    }
    set email(value) {
        this.throwSetError();
    }

    get name() {
        return Joi.string()
            .min(3)
            .pattern(/^[a-zA-Z0-9. ]*$/)
            .allow('', null)
            .messages({
                "string.pattern.base":
                    "Should contains letters and dot [.] only",
                "string.base": "This field is required",
            });
    }
    set name(value) {
        this.throwSetError();
    }
    get nameRequired() {
        return Joi.string()
            .min(3)
            .pattern(/^[a-zA-Z0-9. ]*$/)
            .required()
            .messages({
                "string.pattern.base":
                    "Should contains letters and dot [.] only",
                "string.base": "This field is required",
            });
    }
    set nameRequired(value) {
        this.throwSetError();
    }

    get password() {
        return passwordComplexity({
            min: 8,
            max: 25,
            lowerCase: 1,
            // upperCase: 1,
            // numeric: 1,
            symbol: 1,
            requirementCount: 4,
        }).label("Password");
    }
    set password(value) {
        this.throwSetError();
    }

    get confirmPassword() {
        return Joi.any().equal(Joi.ref('password'))
            .required()
            .label('Confirm password')
            .messages({ 'any.only': '{{#label}} does not match' })
    }

    get companyName() {
        return Joi.string()
            .pattern(/^[a-zA-Z0-9 ]*$/)
            .min(2)
            .max(100)
            .allow("")
            .label("Company Name")
            .messages({
                "string.pattern.base": "Should contains letters and numbers only",
            });
    }
    set companyName(value) {
        this.throwSetError();
    }

    get optionalField() {
        return Joi.allow("", null, 0);
    }
    set optionalField(value) {
        this.throwSetError();
    }
    get confirmationCode() {
        return Joi.string()
            .length(6)
            .pattern(/^[0-9]+$/)
            .required()
            .messages({
                "string.pattern.base": "Invalid code",
                "string.length": "Invalid code",
            })
            .label("Confirmation code");
    }
    set confirmationCode(value) {
        this.throwSetError();
    }
    set phoneNumber(value) {
        this.throwSetError();
    }
    get phoneNumber() {
        return Joi.number().min(1000000000).max(9999999999).messages({
            "number.min": "Please enter a valid phone number",
            "number.max": "Please enter a valid phone number",
        });
    }
    get postalCode() {
        return Joi.string().length(6).messages({
            "string.length": "Please enter a valid pin code",
        });
    }
    set postalCode(value) {
        this.throwSetError();
    }
    get url() {
        return Joi.string();
    }
    set url(value) {
        this.throwSetError();
    }
    get string() {
        return Joi.string();
    }
    set string(value) {
        this.throwSetError();
    }
    get stringRequired() {
        return Joi.string().required();
    }
    set stringRequired(value) {
        this.throwSetError();
    }
    get alphaNumeric() {
        return Joi.string().alphanum();
    }
    set alphaNumeric(value) {
        this.throwSetError();
    }
    get alphaNumericRequired() {
        return Joi.string().alphanum().required();
    }
    set alphaNumericRequired(value) {
        this.throwSetError();
    }
    get number() {
        return Joi.number();
    }
    set number(value) {
        this.throwSetError();
    }
    get positiveNumberRequired() {
        return Joi.number().positive().allow(0).required();
    }
    set positiveNumberRequired(value) {
        this.throwSetError();
    }
    get positiveNumber() {
        return Joi.number().positive().allow(0, null, '');
    }
    set positiveNumber(value) {
        this.throwSetError();
    }
    get numberRequired() {
        return Joi.number().required();
    }
    set numberRequired(value) {
        this.throwSetError();
    }
    get boolean() {
        return Joi.boolean();
    }
    set boolean(value) {
        this.throwSetError();
    }
    get booleanRequired() {
        return Joi.boolean().required();
    }
    set booleanRequired(value) {
        this.throwSetError();
    }
    get date() {
        return Joi.date().raw();
    }
    set date(value) {
        this.throwSetError();
    }
    get dateRequired() {
        return Joi.date().raw().required();
    }
    set dateRequired(value) {
        this.throwSetError();
    }
    get any() {
        return Joi.any();
    }
    set any(value) {
        this.throwSetError();
    }
    public array<T = any>() {
        return Joi.array<T>();
    }
    public arrayRequired<T = any>() {
        return Joi.array<T>().required();
    }
    public object<T = any>(schema: any) {
        return Joi.object<T>(schema);
    }
    public objectRequired<T = any>() {
        return Joi.object<T>().required();
    }
    throwSetError() {
        throw new Error("It's not possible to set this field");
    }
}

const formValidationRules = new FormValidationRules();
export default formValidationRules;
