import React, { forwardRef } from 'react';
import FieldErrorText from '../FormBuilder/FieldErrorText';
import { HTMLAttributes } from 'react';

export interface ISelectTypeItem {
    title: string;
    description?: string;
    id: string;
    value?: string | number | readonly string[]
}
export interface ISelectType extends HTMLAttributes<HTMLInputElement> {
    items: ISelectTypeItem[];
    error?: string;
}

// Wrap the component with forwardRef
const SelectType = forwardRef<HTMLInputElement, ISelectType>(({ items, error, ...rest }, ref) => {

    return (
        <div className="row d-flex align-items-stretch h-min-150px">
            {items.map((v) => (
                <div key={v.id} className="col-lg-6">
                    <input
                        type="radio"
                        className="btn-check"
                        value={v.value}
                        id={v.id}
                        ref={ref} // Pass ref here
                        {...rest}
                    />
                    <label
                        className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center h-100"
                        htmlFor={v.id}
                    >
                        <i className="ki-outline ki-briefcase fs-3x me-5"></i>
                        <span className="d-block fw-semibold text-start">
                            <span className="text-gray-900 fw-bold d-block fs-4 mb-2">
                                {v.title}
                            </span>
                            {v?.description && (
                                <span className="text-muted fw-semibold fs-6">
                                    {v.description}
                                </span>
                            )}
                        </span>
                    </label>
                </div>
            ))}
            {error && <FieldErrorText error={error} />}
        </div>
    );
});

export default SelectType;
